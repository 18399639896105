<template>
    <div id="belepes" class="login block--big position-relative bg-white">
        <div class="container-xl login__content">
            <div class="row">
                <div class="col-12 col-xl-10 offset-xl-1">
                    <h2 class="font-header text-center pb-5">Bejelentkezés</h2>  

                    <div v-if="needSocialLogin">
                        <h3 class="text-center text-primary fw-bold mt-5">Közösségi fiókkal</h3>

                        <div class="row mt-4">
                            <div class="col-12 col-md-6 offset-md-3 d-flex justify-content-center">
                                <GoogleLogin />
                            </div>
                            <!-- <div class="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mt-3 mt-md-0">
                                <FacebookLogin />
                            </div> -->
                        </div>
                    
                        
                        <!-- divider -->
                        <div class="row mt-4 pt-md-4">
                            <div class="col-12">    
                                <div class="d-flex align-items-center justify-items-center">
                                    <div class="divider flex-1"></div>
                                    <span style="color:#D9D9D9" class="mx-2 font-primary fw-bold">VAGY</span>
                                    <div class="divider flex-1"></div>
                                </div>                        
                            </div>
                        </div>    
                    </div>
                    

                    <div class="row mt-5">   
                        <h3 class="text-center text-primary fw-bold mt-4">E-mail címmel</h3>
                        
                            <!-- ITT KEZDŐDIK A FORM --> 
                            <ValidationObserver 
                                class="col-12"
                                ref="observerLogin" 
                                v-slot="{ handleSubmit }"
                                tag="div"
                            >                                
                                <form 
                                    class="row "
                                    @submit.prevent="handleSubmit(onSubmit)"
                                >
                                    <div class="col-12 col-md-6">
                                        <CustomInput
                                            label="E-mail cím"
                                            name="email"
                                            placeholder="pl.: molnarkata@gmail.com"
                                            tooltip="A játékos e-mail címe amit regisztrációnál használt."
                                            v-model="form.email"
                                            rules="required|email"
                                        />
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <CustomInput
                                            type="password"
                                            label="Jelszó"
                                            name="password"
                                            placeholder="Minimum 8 karakter"
                                            tooltip="Regisztráció során megadott jelszó: legalább 8 karakternek kell lennie és speciális karaktereket tartalmazhat."
                                            v-model="form.password"
                                            rules="required|min:8"
                                        />
                                    </div>
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12 d-flex justify-content-center align-items-center mt-2 mb-0 my-sm-4">
                                                <button 
                                                    class="btn btn-secondary" 
                                                    type="submit"
                                                >Bejelentkezem
                                                    <img src="@/assets/imgs/arrow-circle.svg" class="img-fluid ms-2 mb-1">
                                                </button>
                                            </div>
                                        </div>     
                                    </div>                                                                
                                    <div v-if="loginError" class="alert alert-danger">{{loginError}}</div>
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12 d-flex justify-content-center mt-1">                                       
                                                <router-link :to="{name:'ForgottenPsw'}"                                                    
                                                    class="text-secondary fw-700 text-decoration-underline btn-link text-center mt-0 fs-14"
                                                >
                                                    Elfelejtett jelszó?
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </form>                                
                            </ValidationObserver>                     
                        
                    </div>      
                </div>
            </div>
            
        </div>                        
    </div>    
</template>

<script>
import CustomInput from '@/components/base/CustomInput.vue'
import { ValidationObserver } from 'vee-validate'
import GoogleLogin from '@/components/GoogleLogin.vue'
// import FacebookLogin from '@/components/FacebookLogin.vue'
// import errorMessages from "@/setup/errorMsgs.json"

export default {
    components: {
        ValidationObserver,
        CustomInput,
        GoogleLogin,
        // FacebookLogin       
    },
    data () {
        return {
           form: {
                email: '',
                password: ''
            }, 
            loginError: "",
            needSocialLogin: true
        }
    },
    methods: {
        onSubmit(){        
            let _this = this       

            this.login(this.form.email, this.form.password).then((res)=>{
                if (res.data.status == true){
                    //reset form
                    if (this.$route.name == 'EmailVerification'){
                        this.$router.push({name:'Home', params:{ scrollTo: 'palyazat' }})
                    } else {
                        this.setFormDefaultState()    
                    }
                    
                }
                                
           }).catch((err)=>{            
                //this.setErrorMsgs(err)
                _this.setErrorMsgs(err, 'observerLogin').catch(()=>{
                    this.loginError = "Sikertelen bejelentkezés"

                    setTimeout(() => {
                        this.loginError = ""
                    }, 5000);
                })
           })
        },
        setFormDefaultState(){
            this.form = {
                email: '',
                password: ''
            }
        },
        goToError(err){            
            
            this.setErrorMsgs(err, 'observerLogin').then(()=>{
                let firstError = document.querySelector('#loginModal .input-error')
                if (firstError)
                    firstError.scrollIntoView()                             
            })                                       
        },   
       
    },
    mounted(){
        if (this.$route.name == 'EmailVerification'){
            this.needSocialLogin = false
        }
    }
    
}
</script>