<template>
<div>

    <!-- google login -->
    <div class="d-flex justify-content-center justify-content-md-end">
        <div ref="googleLoginBtn"></div>        
        <div v-if="googleLoginError">Valami hiba történt a Google bejelentkeés során</div>
        <!-- error -->
        <div v-if="googleLoginError">Hiba a regisztráció során</div>
    </div>


    <!-- checkbox POPUP - for registration -->
    <div class="modal" tabindex="-1" id="googleRegModal" data-bs-backdrop="false">
        <div class="modal-dialog modal-lg  modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="text-center">                
                    <h3 class="text-center text-uppercase text-primary fw-bold mt-4">Regisztráció Google fiókkal</h3>                                    
                    <button type="button" data-bs-dismiss="modal" aria-label="Close" class="position-absolute close bg-white border-none " style="top:10px;right:10px;border:none!important;">
                        <span aria-hidden="true">&times;</span>
                    </button>

                   
                </div>
                <div class="modal-body font-nunito">
                    <!-- legal checkboxes -->
                    <ValidationObserver 
                        class="col-12 col-xl-10 offset-xl-1"
                        ref="observerReg" 
                        v-slot="{ handleSubmit }"
                        tag="div"
                    >
                    <form
                            class="" 
                            @submit.prevent="handleSubmit(googleRegSubmit)"
                        >      
                    <!-- JÁTÉKSZABÁLY ELFOGADÁSA -->
                        <div class="col-12">
                            <CheckBox
                                class="mt-4"
                                name="gfb_condition"
                                id="gfb_condition0"
                                v-model="form.condition"
                                :checked="form.condition"
                                rules="required|acceptConditions"                                  
                            >
                                <p 
                                    class="mb-0 text-black"
                                >
                                    Kijelentem, hogy 18 éves elmúltam, a <span>&nbsp;</span>
                                    <a class="text-primary text-underline fw-700" :href="$store.state.file_url" target="_blank">Játékszabályzatot</a>
                                    <span>&nbsp;</span> elolvastam, annak feltételeit elfogadom, és az adatkezeléssel kapcsolatos, a 14. pontban foglalt tájékoztatást kifejezetten tudomásul vettem.
                                    <span class="text-red fw-bold">*</span>
                                </p>
                            </CheckBox>
                        </div>

                        <!-- ÁSZF ELFOGADÁSA -->
                        <div class="col-12">
                            <CheckBox
                                class="mt-2"
                                name="gfb_consent"
                                id="gfb_consent0"
                                v-model="form.consent"
                                :checked="form.consent"
                                rules="required|acceptConditions"
                            >
                                <p class="mb-0 text-black"> 
                                    <span>Hozzájárulok ahhoz, hogy a nyertesként történő kisorsolásom esetén a szervező a vezetéknevem kezdőbetűjét és a keresztnevemet, a nyerés tényét, valamint a nyeremény megjelölését az alábbi honlapon közzé tegye: <a class="text-primary text-underline fw-700" href="https://www.heineken.com/hu/hu/nyeremenyjatek">heineken.com/hu/hu/nyeremenyjatek</a> Az adatkezelésre vonatkozó részletes információkat a hivatalos </span>
                                    <span>&nbsp;</span>
                                    <a class="text-primary text-underline fw-700" :href="$store.state.file_url" target="_blank">Játékszabályzat 14. pontja</a>
                                    <span>&nbsp;</span>
                                    <span>tartalmazza.</span>
                                    <span class="text-red fw-bold">*</span>    
                                </p>
                            </CheckBox>
                        </div>

                        <!-- FELIRATKOZÁS A HÍRLEVÉLRE -->
                        <div class="col-12">
                            <CheckBox
                                class="mt-2"
                                name="gfb_newsletter"
                                id="gfb_newsletter0"
                                v-model="form.newsletter"
                                :checked="form.newsletter"
                                @input="changeMarketing()"
                            >
                                <div>
                                    <p class="text-black">Hozzájárulok ahhoz, hogy az Adatkezelő közvetlen marketing célú megkereséseket küldjön a részemre, például email-ben, vagy egyéb módon (opt-in).</p>
                                </div>
                            </CheckBox>
                        </div>
                        
                        <div class="col-12 col-md-6">
                            <SelectDate v-if="form.newsletter === 1"
                                name="gfb_birth_date"
                                label="Kérlek add meg a születési dátumod" 
                                placeholder="ÉÉÉÉ-HH-NN"
                                @selectedDate="form.birth_date = dateTimeToSqlFormat($event)"
                                :rules="form.newsletter != 0 ? 'required':''"
                            />
                        </div>

                        <div class="mt-3 mb-md-4 text-sm-center d-flex">                                
                            <span class="text-red fw-bold">*</span>                                    
                            <p class="m-0 info-text text-black" style="font-size: 14px">A csillaggal jelölt mezők kitöltése szükséges.</p>
                        </div>   

                        <div class="divider m-4"></div>
                        <!-- buttons -->
                        <div class="d-flex align-items-center justify-content-center">
                            <button type="button" class="btn btn-primary mx-1 mx-md-2 mb-2 mb-md-0" data-bs-dismiss="modal">Mégse</button>
                            <button type="submit" class="btn btn-secondary mx-1 mx-md-2 mb-2 mb-md-0">Regisztrálok</button>
                        </div>

                        <!-- error -->
                        <div v-if="googleLoginError2" style="color:red;" class="error input-error mt-2">Hiba a regisztráció során - {{googleLoginError2Text}}</div>                    
                    </form>
                    </ValidationObserver> <!-- ITT VÉGZŐDIK A FORM -->
                </div>           
            </div>
        </div>
    </div>

</div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import CheckBox from '@/components/base/CheckBox.vue'
import SelectDate from '@/components/base/SelectDate.vue'


export default {
    data(){
        return{
            googleLoginError: false,
            googleLoginError2: false,
            googleLoginError2Text: null,
            google_id_token: null,
            form: {                
                condition: null,
                consent: null,
                newsletter: 0,
                birth_date:'',                                    
           }, 
        }
    },
    components:{
        ValidationObserver,
        CheckBox,
        SelectDate
    },
    methods:{
        handleCredentialResponse(response)    {
            // console.log('handleCredentialResponse')
            // console.log('handleCredentialResponse', response)
            this.google_id_token = response.credential
            this.sendGoogleTokenToCheck( this.google_id_token)
        },
        singOutFromGoogle(){
            window.google.accounts.id.disableAutoSelect()
        },
        changeMarketing(){
            if ( parseInt(this.form.newsletter) === 0){
                this.form.birth_date = ''
            }
        },
        sendGoogleTokenToCheck(token){
            console.log('sendGoogleTokenToCheck1')
            this.post('promologin/google', {
                google_id_token: token
            }).then((res) => {
                console.log("sendGoogleTokenToCheck", res)
                //success - logged in
                this.$store.state.user = res.data.user
                this.$store.state.token = res.data.token;
                localStorage.setItem("token", res.data.token);
                this.closePopup()
                this.$router.push({name:'Home', params:{ scrollTo: 'palyazat' }}).catch(()=>{});
            }).catch((err)=>{
                console.log('google login error', err)

                let errorsArray = Object.entries(err.response.data.error);
                for (const [errorName, errorValue] of errorsArray){
                    console.log(errorName, errorValue)

                    if (errorName == 'google_id_token'){
                        //not registered
                        if( errorValue == 'not_registered' && errorsArray.length == 1){                            
                            this.showRegCheckboxPopup()
                        }
                        
                        //required
                        if ( errorValue == 'required'){                            
                            this.googleLoginError = true
                        }
                    }
                }
            })
        },
        showRegCheckboxPopup(){
            console.log('show checkbox popup for registration')

            var googleRegModal = new this.$bootstrap.Modal(document.getElementById('googleRegModal'))
            googleRegModal.show()

            // setTimeout(() => {
            //     let modalElement = document.querySelector('#googleRegModal .modal-content')
            //     //modalElement.scrollIntoView()        
            //     const rect = modalElement.getBoundingClientRect();
            //     const topPos = rect.top + window.scrollY;
            //     window.scrollTo(0,topPos);
            //     console.log('scroll to google reg popup')
            // }, 1500);
            
        },
        closePopup(){
            console.log('closePopup')
            var googleRegModal = new this.$bootstrap.Modal(document.getElementById('googleRegModal'))
            googleRegModal.hide()
            document.body.removeAttribute('style')
            document.body.removeAttribute('class')
        },
        setFormDefaultState(){
            this.googleLoginError = false,
            this.googleLoginError2 = false,
            this.google_id_token = null,
            this.form = {                
                condition: null,
                consent: null,
                newsletter: 0,
                birth_date:'',                                    
           }
        },
        googleRegSubmit(){
            console.log('googleRegSubmit')

            var sendData = JSON.parse(JSON.stringify(this.form)); 
            sendData.google_id_token = this.google_id_token      

            this.post('register/google',sendData).then((res)=>{
                if (res.data.status == true){                                        

                    //go to the reg 
                    console.log('go to success reg from google reg')
                    //this.$router.push({name: 'successRegSocial', params: {result: 'success'}})
                    //logged in the user
                    this.closePopup()
                    this.sendGoogleTokenToCheck( this.google_id_token)
                    
                    //reset form
                    this.setFormDefaultState()                           
                }
                                
           }).catch((err)=>{                                                                                     
                // this.goToError(err)                                                          
                console.log('google reg error', err)
                this.googleLoginError2 = true

                let errorsArray = Object.entries(err.response.data.error)
                for (const [errorName, errorValue] of errorsArray) {
                    if (errorName == 'google_id_token' && errorValue == 'occupied'){
                        this.googleLoginError2Text = 'Ezzel az e-mail címmel már regisztráltak'
                    } else if (errorName == 'email' && errorValue == 'occupied'){
                        this.googleLoginError2Text = 'Ezzel az e-mail címmel már regisztráltak'
                    } else if (errorName == 'birth_date' && errorValue == 'must_be_adult'){
                        this.googleLoginError2Text = 'A hírlevélhez való feliratkozáshoz 18 éves kort be kell tölteni.'
                    } else {
                        this.googleLoginError2Text = 'Hiba'
                    }

                }

           })
        }
    },
    mounted() {
        
       var script1 =  document.createElement('SCRIPT');
       script1.src = 'https://apis.google.com/js/platform.js';
       var head = document.getElementsByTagName('head');
       head[0].appendChild(script1);

       var script2 =  document.createElement('SCRIPT');
       script2.src = 'https://accounts.google.com/gsi/client';
       head[0].appendChild(script2);

      const gClientId = '755451403409-ontak9mk9odprptnvm2fjvejimh5adsh.apps.googleusercontent.com'

      var i = 0;
      var intv = setInterval(() => {
        console.log('mmenet',i++);
        if (window.google)
        {
            clearInterval(intv);
            
            window.google.accounts.id.initialize({
                client_id: gClientId,
                callback: this.handleCredentialResponse,
                auto_select: false
            })        
            window.google.accounts.id.renderButton(
                this.$refs.googleLoginBtn, {
                    text: 'signin_with', // or 'signup_with' | 'continue_with' | 'signin'
                    size: 'large', // or 'small' | 'medium'
                    width: '300', // max width 400
                    height: '54',
                    theme: 'outline', // or 'filled_black' |  'filled_blue'
                    logo_alignment: 'left', // or 'center'
                    locale: 'hu'
                }
            )  
            window.google.accounts.id.prompt();
    
            console.log('mounted end')
        }

     }, 100);
      
    },
}
</script>
